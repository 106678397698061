import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ActivityIndicator from "../components/ActivityIndicator/ActivityIndicator";

const RoyaltyHome = lazy(() => import("../screens/Home/RoyaltyHome/RoyaltyHome"));
const Login = lazy(() => import("../screens/Login/Login"));
const Layout = lazy(() => import("./Layout"));
const ForgotPassword = lazy(
  () => import("../screens/ForgotPassword/ForgotPassword")
);
const Profile = lazy(() => import("../screens/Profile/Profile"));
const ProfileEdit = lazy(() => import("../screens/Profile/ProfileEdit"));
const ChangePassword = lazy(
  () => import("../screens/ChangePassword/ChangePassword")
);
const TitleISBNUpdate = lazy(
  () => import("../screens/Title/TitleISBNUpdate/TitleISBNUpdate")
);

const NewTitle = lazy(() => import("../screens/Title/NewTitle/NewTitle"));
const ProductHome = lazy(() => import("../screens/Home/ProductsHome/ProductsHome"));
const MultipleSeriesTitle = lazy(
  () => import("../screens/Title/MultipleSeriesTitle/MultipleSeriesTitle")
);
const MultipleSeriesTitleSearch = lazy(
  () =>
    import("../screens/Title/MultipleSeriesTitleSearch/MultipleSeriesTitleSearch")
);
const TitleView = lazy(() => import("../screens/Title/TitleView/TitleView"));
const TitleSearch = lazy(() => import("../screens/Title/TitleSearch/TitleSearch"));

const TitleDetailss = lazy(
  () => import("../screens/Title/TitleDetails/TitleDetails")
);
const TitlePermissionDetails = lazy(
  () => import("../screens/Title/TitlePermission/TitlePermissionDetails")
);
const TitlePermissionInsert = lazy(
  () => import("../screens/Title/TitlePermission/TitlePermissionInsert")
);
const TitlePermissionView = lazy(
  () => import("../screens/Title/TitlePermission/TitlePermissionView")
);
const TitlePermissionSearch = lazy(
  () => import("../screens/Title/TitlePermission/TitlePermissionSearch")
);
const AddCustomTitle = lazy(
  () => import("../screens/Title/AddCustomTitle/AddCustomTitle")
);
const AssignmentContractSearch = lazy(
  () =>
    import(
      "../screens/Cantract/AssignmentContract/AssignmentContractSearch/AssignmentContractSearch"
    )
);
const AssignmentContractViews = lazy(
  () =>
    import(
      "../screens/Cantract/AssignmentContract/AssignmentContractView/AssignmentContractView"
    )
);
const AssignmentContractDetail = lazy(
  () =>
    import(
      "../screens/Cantract/AssignmentContract/AssignmentContractDetails/AssignmentContractDetails"
    )
);
const AssignmentContractRequest = lazy(
  () =>
    import(
      "../screens/Cantract/AssignmentContract/AssignmentContractRequest/AssignmentContractRequest"
    )
);
const AssignmentContractRequestDetails = lazy(
  () =>
    import(
      "../screens/Cantract/AssignmentContract/AssignmentContractRequestDetails/AssignmentContractRequestDetails"
    )
);

const AuthorSearch = lazy(
  () => import("../screens/Author/AuthorSearch/AuthorSearch")
);
const AuthorView = lazy(() => import("../screens/Author/AuthorView/AuthorView"));
const AuthorCreate = lazy(
  () => import("../screens/Author/AuthorCreate/AuthorCreate")
);
const AuthorDetails = lazy(
  () => import("../screens/Author/AuthorDetails/AuthorDetails")
);
const AuthorUpdate = lazy(
  () => import("../screens/Author/AuthorUpdate/AuthorUpdate")
);
const TitleLicenseSearch = lazy(
  () =>
    import("../screens/Cantract/TitleLicense/TitleLicenseSearch/TitleLicenseSearch")
);
const TitleLicenseView = lazy(
  () => import("../screens/Cantract/TitleLicense/TitleLicenseView/TitleLicenseView")
);
const TitleLicenseDetails = lazy(
  () =>
    import(
      "../screens/Cantract/TitleLicense/TitleLicenseDetails/TitleLicenseDetails"
    )
);
const TitleLicenseAddendumSearch = lazy(
  () =>
    import(
      "../screens/Cantract/TitleLicenseAddendum/TitleLicenseAddendumSearch/TitleLicenseAddendumSearch"
    )
);
const TitleLicenseAddendumView = lazy(
  () =>
    import(
      "../screens/Cantract/TitleLicenseAddendum/TitleLicenseAddendumView/TitleLicenseAddendumView"
    )
);
const TitleLicenseAddendumDetails = lazy(
  () =>
    import(
      "../screens/Cantract/TitleLicenseAddendum/TitleLicenseAddendumDetails/TitleLicenseAddendumDetails"
    )
);
const AssingmentContractUpdate = lazy(
  () =>
    import(
      "../screens/Cantract/AssignmentContract/AssingmentContractUpdate/AssingmentContractUpdate"
    )
);
const CompleteTitleDetails = lazy(
  () => import("../screens/Reports/CompleteTitleDetails/CompleteTitleDetails")
);
const ConstructionPage = lazy(() => import("../screens/ConstructionPage"));

const NewTitleLicense = lazy(
  () => import("../screens/Cantract/TitleLicense/NewTitleLicense/NewTitleLicense")
);
const TitleLicenseUpdate = lazy(
  () =>
    import("../screens/Cantract/TitleLicense/TitleLicenseUpdate/TitleLicenseUpdate")
);
const TitleLicenseAddendumUpdate = lazy(
  () =>
    import(
      "../screens/Cantract/TitleLicenseAddendum/TitleLicenseAddendumUpdate/TitleLicenseAddendumUpdate"
    )
);
const NewTitleLicenseAddendum = lazy(
  () =>
    import(
      "../screens/Cantract/TitleLicenseAddendum/NewTitleLicenseAddendum/NewTitleLicenseAddendum"
    )
);
const MultipleTitleSearch = lazy(
  () => import("../screens/Cantract/MultipleTitleLink/MultipleTitleSearch")
);

const MultipleTitleUpdate = lazy(
  () => import("../screens/Cantract/MultipleTitleLink/MultipleTitleUpdate")
);
const Help = lazy(() => import("../screens/Help/Help"));

const UserManagementView = lazy(
  () => import("../screens/Admin/UserManagement/UserManagementView")
);
const CreateUser = lazy(() => import("../screens/Admin/UserManagement/UserCreate"));

const UserDetails = lazy(
  () => import("../screens/Admin/UserManagement/UserDetails")
);
const PubCenterView = lazy(
  () => import("../screens/Admin/PubCenterManagement/PubCenterManagement")
);
const CreatePubCenter = lazy(
  () => import("../screens/Admin/PubCenterManagement/CreatePubCenter")
);

const PubCenterDetails = lazy(
  () => import("../screens/Admin/PubCenterManagement/PubCenterDetails")
);
const AuthorManagementView = lazy(
  () => import("../screens/Admin/AuthorManagement/AuthorManagementView")
);
const AdminAuthorCreate = lazy(
  () => import("../screens/Admin/AuthorManagement/AuthorCreate")
);
const AdminAuthorDetails = lazy(
  () => import("../screens/Admin/AuthorManagement/AuthorDetails")
);
const PublicationManagementView = lazy(
  () => import("../screens/Admin/PublicationManagement/PublicationManagementView")
);
const CreatePublicationCompany = lazy(
  () => import("../screens/Admin/PublicationManagement/CreatePublicationCompany")
);
const PublicationCompanyDetails = lazy(
  () => import("../screens/Admin/PublicationManagement/PublicationCompanyDetails")
);
const ImprintsManagementView = lazy(
  () => import("../screens/Admin/ImprintsManagement/ImprintsManagementView")
);
const ImprintCreate = lazy(
  () => import("../screens/Admin/ImprintsManagement/ImprintCreate")
);
const ImprintDetails = lazy(
  () => import("../screens/Admin/ImprintsManagement/ImprintDetails")
);
const RoleManagementView = lazy(
  () => import("../screens/Admin/RoleManagement/RoleManagementView")
);
const RoleManagementCreate = lazy(
  () => import("../screens/Admin/RoleManagement/RoleManagementCreate")
);
const RoleManagementDetailsView = lazy(
  () => import("../screens/Admin/RoleManagement/RoleManagementDetails")
);
const DepartmentManagementView = lazy(
  () => import("../screens/Admin/DepartmentManagement/DepartmentManagementView")
);
const DepartmentManagementDetailsView = lazy(
  () => import("../screens/Admin/DepartmentManagement/DepartmentManagementDetails")
);
const DepartmentManagementCreate = lazy(
  () => import("../screens/Admin/DepartmentManagement/DepartmentManagementCreate")
);

const ProjectsHome = lazy(() => import("../screens/Title/Projects/ProjectsHome"));
const AddProject = lazy(() => import("../screens/Title/Projects/AddProject"));
const ProjectSummary = lazy(
  () => import("../screens/Title/Projects/ProjectSummary")
);
const AddAssetDetails = lazy(
  () => import("../screens/Title/Projects/AddAssetDetails")
);

const Router = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<ActivityIndicator />}>
        <Routes>
          <Route element={<Layout />}>
            <Route path="profile" element={<Profile />}></Route>
            <Route path="profile/edit" element={<ProfileEdit />}></Route>
            <Route path="royalty/home" element={<RoyaltyHome />}></Route>
            <Route path="products/home" element={<ProductHome />}></Route>
            <Route path="title/new_title" element={<NewTitle />}></Route>
            <Route
              path="title/multiple_series_title"
              element={<MultipleSeriesTitle />}></Route>
            <Route
              path="title/multiple_series_title_search"
              element={<MultipleSeriesTitleSearch />}></Route>
            <Route path="title/title_view/:action" element={<TitleView />}></Route>
            <Route
              path="title/title_search/:action"
              element={<TitleSearch />}></Route>
            <Route path="title/title_details" element={<TitleDetailss />}></Route>
            <Route
              path="title/title_isbn_update"
              element={<TitleISBNUpdate />}></Route>
            <Route
              path="title/title_permission_details"
              element={<TitlePermissionDetails />}></Route>
            <Route
              path="title/title_permission_insert"
              element={<TitlePermissionInsert />}></Route>
            <Route
              path="title/title_permission_view/:action"
              element={<TitlePermissionView />}></Route>
            <Route
              path="title/title_permission_search/:action"
              element={<TitlePermissionSearch />}></Route>

            <Route
              path="title/title_view/custom_title"
              element={<AddCustomTitle />}></Route>

            <Route path="title/projects_home" element={<ProjectsHome />}></Route>
            <Route path="title/add_project/:action" element={<AddProject />}></Route>
            <Route path="title/project_summary" element={<ProjectSummary />}></Route>
            <Route
              path="title/project_asset_details/:action"
              element={<AddAssetDetails />}></Route>

            <Route
              path="contracts/title_license_search/:action"
              element={<TitleLicenseSearch />}></Route>
            <Route
              path="contracts/title_license_view/:action"
              element={<TitleLicenseView />}></Route>
            <Route
              path="contracts/title_license_details"
              element={<TitleLicenseDetails />}></Route>
            <Route
              path="contracts/title_license_new"
              element={<NewTitleLicense />}></Route>
            <Route
              path="contracts/title_license_update/:action"
              element={<TitleLicenseUpdate />}></Route>

            <Route
              path="contracts/title_license_addendum_search/:action"
              element={<TitleLicenseAddendumSearch />}></Route>
            <Route
              path="contracts/title_license_addendum_view/:action"
              element={<TitleLicenseAddendumView />}></Route>
            <Route
              path="contracts/title_license_addendum_details"
              element={<TitleLicenseAddendumDetails />}></Route>
            <Route
              path="contracts/title_license_addendum_update"
              element={<TitleLicenseAddendumUpdate />}></Route>
            <Route
              path="contracts/title_license_addendum_new"
              element={<NewTitleLicenseAddendum />}></Route>

            <Route
              path="contracts/assingment_contract_search/:action"
              element={<AssignmentContractSearch />}></Route>
            <Route
              path="contracts/assingment_contract_view/:action"
              element={<AssignmentContractViews />}></Route>
            <Route
              path="contracts/assingment_contract_details"
              element={<AssignmentContractDetail />}></Route>
            <Route
              path="contracts/assingment_contract_request"
              element={<AssignmentContractRequest />}></Route>
            <Route
              path="contracts/assingment_contract_request_details"
              element={<AssignmentContractRequestDetails />}></Route>
            <Route
              path="contracts/assingment_contract_update"
              element={<AssingmentContractUpdate />}></Route>
            <Route
              path="contracts/multilple_title_search"
              element={<MultipleTitleSearch />}></Route>
            <Route
              path="contracts/multilple_title_update"
              element={<MultipleTitleUpdate />}></Route>
            <Route
              path="reports/complete_title_details"
              element={<CompleteTitleDetails />}></Route>
            <Route
              path="author/author_search/:action"
              element={<AuthorSearch />}></Route>
            <Route
              path="author/author_view/:action"
              element={<AuthorView />}></Route>
            <Route path="author/author_create" element={<AuthorCreate />}></Route>
            <Route path="author/author_details" element={<AuthorDetails />}></Route>
            <Route path="author/author_update" element={<AuthorUpdate />}></Route>

            <Route path="construction_page" element={<ConstructionPage />}></Route>
            <Route path="help" element={<Help />}></Route>
            <Route
              path="admin/user_management_view"
              element={<UserManagementView />}></Route>
            <Route path="admin/create_user/:action" element={<CreateUser />}></Route>
            <Route path="admin/user_details" element={<UserDetails />}></Route>

            <Route path="admin/pub_center_view" element={<PubCenterView />}></Route>
            <Route
              path="admin/create_pub_center/:action"
              element={<CreatePubCenter />}></Route>
            <Route
              path="admin/pub_center_details"
              element={<PubCenterDetails />}></Route>
            <Route
              path="admin/author_management_view"
              element={<AuthorManagementView />}></Route>
            <Route
              path="admin/author_create/:action"
              element={<AdminAuthorCreate />}></Route>
            <Route
              path="admin/author_details"
              element={<AdminAuthorDetails />}></Route>
            <Route
              path="admin/publication_management_view"
              element={<PublicationManagementView />}></Route>
            <Route
              path="admin/create_publcation_company/:action"
              element={<CreatePublicationCompany />}></Route>
            <Route
              path="admin/publcation_company_details"
              element={<PublicationCompanyDetails />}></Route>
            <Route
              path="admin/imprints_management_view"
              element={<ImprintsManagementView />}></Route>
            <Route
              path="admin/create_imprints/:action"
              element={<ImprintCreate />}></Route>
            <Route
              path="admin/imprints_details"
              element={<ImprintDetails />}></Route>
            <Route
              path="admin/role_management_view"
              element={<RoleManagementView />}></Route>
            <Route
              path="admin/role_management_details_view"
              element={<RoleManagementDetailsView />}></Route>
            <Route
              path="admin/create_role_management/:action"
              element={<RoleManagementCreate />}></Route>
            <Route
              path="admin/department_management_view"
              element={<DepartmentManagementView />}></Route>
            <Route
              path="admin/department_management_details_view"
              element={<DepartmentManagementDetailsView />}></Route>
            <Route
              path="admin/create_department_management/:action"
              element={<DepartmentManagementCreate />}></Route>
          </Route>

          <Route path="/" element={<Login />}></Route>
          <Route path="forgot_password" element={<ForgotPassword />}></Route>
          <Route path="change_password" element={<ChangePassword />}></Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
